import styled from 'styled-components';

export const LandingPageContainer = styled.div`
	background-color: #081327;

	/* background-color: #09040f;

	background-image: linear-gradient(360deg, #09040f, rgba(0, 0, 0, 0.5)),
		linear-gradient(
			140deg,
			#23262d 13%,
			#034083 31%,
			#9c468e 65%,
			#9c468e 71%,
			#1e1311 82%,
			#1e1311
		); */

	.team-section {
		margin-top: 2rem;
	}
`;
